<template>
  <div class="flex items-center gap-2 text-3xl tracking-tight">
    <span class="-mt-1">App</span>
    <img
      src="@/assets/images/logo.svg"
      class="h-11"
    />
    <span>{{ config.public.siteName }}</span>
  </div>
</template>

<script setup lang="ts">
const config = useRuntimeConfig()
</script>
