<template>
  <component
    :is="props.tag"
    class="mx-auto w-full min-w-[1440px] max-w-[2000px] px-7"
  >
    <slot />
  </component>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    tag?: string
  }>(),
  {
    tag: "div",
  }
)
</script>
