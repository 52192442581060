<template>
  <button
    class="h-fit text-3xl leading-none"
    @click="toggle"
  >
    <Icon name="material-symbols:clear-day-rounded" />
  </button>
</template>

<script setup lang="ts">
const colorMode = useColorMode()

function toggle() {
  colorMode.preference = colorMode.value === "light" ? "dark" : "light"
}
</script>
