<template>
  <v-container
    tag="header"
    class="flex items-center gap-3 py-7"
  >
    <nuxt-link
      to="/"
      class="mr-auto text-inherit"
    >
      <v-logo />
    </nuxt-link>

    <slot> </slot>

    <v-theme-switch />
  </v-container>
</template>

<script setup lang="ts"></script>
